<div class="my-8">
  <div class="font-semibold md:text-2xl text-xl lg:w-8/12 md:w-9/12 w-11/12 m-auto whitespace-pre-wrap ">
    {{text}}

  </div>
    <div class="my-8 text-justify w-9/12 m-auto">
      <ng-content></ng-content>
    </div>

</div>
