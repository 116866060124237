<div class="w-full h-screen px-8 "> <!-- bg-gradient-to-r from-green-400 to-blue-500 -->
  <div class="static h-full w-full bg-cover bg-no-repeat bg-center" style="background-image: url('assets/index/background.svg');">
    <div class="flex flex-col md:flex-row w-full ">
      <div class="lg:w-6/12 w-full">
        <img src="assets/sala/students.svg" class="w-full md:h-screen h-auto md:h-auto lg:p-16 p-4">
      </div>

      <div class=" font-extrabold md:h-screen h-auto flex items-center  justify-items-center lg:w-6/12 w-full ">
        <div class="w-full">
          <div class="border-4 border-green-500 border-dotted  lg:p-12 md:p-6 p-2 bg-green-50 shadow-2xl rounded-br-3xl m-auto">
            <span class="bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-purple-600 lg:text-6xl md:text-4xl text-2xl" >
              A veces la vida no es cuestión de aptitud, <br>   sino de <span class="lg:text-7xl md:text-5xl text-3xl">  actitud .</span>
            </span>
          </div>

          <div class="flex-col items-center m-auto">
            <div class="m-auto mt-8">
              <img src="assets/index/chrome.svg" class="w-20 m-auto">
              <p class="text-xl font-semibold text-center text-gray-600">
                <span class="font-extrabold text-gray-900 lg:text-5xl md:text-3xl text-xl ">
                  ¡
                    RECUERDA
                  !
                </span>
                <br> Es necesario usar Chrome
              </p>
              <div class="text-center">
                <m-button type="normal" (click)="start()">Comenzar </m-button>
              </div>

            </div>

          </div>
        </div>

      </div>
    </div>

  </div>
</div>
